import { Component, OnInit } from '@angular/core';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-booking-success',
  templateUrl: './booking-success.component.html',
  styleUrls: ['./booking-success.component.css']
})
export class BookingSuccessComponent implements OnInit {
  settings = SITE_SETTINGS;
  
  constructor() { }

  ngOnInit(): void {
  }

}
